import { Divider } from '@mui/material'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Link } from 'react-router-dom'


const FirstTimeFixed = () => {


  const state = {

    series: [44, 55, 41,],
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      grid: {
        padding: {
          bottom: -150
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: ""
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }
  return (
    <>
      
        <div className='flex flex-col px-4 p-4 shadow-lg  '>
          <div className='px-4'><h1 className='text-xl font-bold text-[#86048A]'>First Time Fixed Rate</h1></div>
          <div className='grid grid-cols-2 gap-4 justify-center items-center px-4 p-4'>
            <div className='flex flex-col   shadow'>
              {/* <ApexCharts options="" series="" type="donut" /> */}
              <ReactApexChart options={state.options} series={state.series} type="donut" />
              <div className=' flex relative -top-18 -ml-14 justify-center items-center '>
                <p className=''>Total Count</p>
              </div>

              {/* <hr className='border border-gray-500'/> */}
            </div>
            <div className='grid grid-cols-2 justify-center items-center p-4 px-4 gap-8  '>
              <Link to="/normal"> <div className='flex justify-center items-center border-[3px] border-[#21B943]  p-14'>
                <p className='flex justify-center  '>Normal</p>
              </div>
              </Link>
              <Link to="/critical"> <div className='flex justify-center items-center border-[3px] p-14  border-[#FF0000] '>
                <p>Critical</p>
              </div></Link>
              <Link to="/medium">  <div className=' flex justify-center items-center border-[3px] p-14 border-[#FF9900] '>
                <p>Medium</p>
              </div></Link>
            </div>
          </div>
        </div>
      

    </>
  )
}

export default FirstTimeFixed