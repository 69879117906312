import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../config/axios'

import {  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import CustomInput from '../../../CustomInput'


const RequireAssetForService = ({ id }) => {
    console.log(id, "ddddd")
    const [asset1, setAsset1] = useState([])
    const AssetReqireForService = () => {
        axiosInstance.get(`support_page/ticket-asset-request-details-of-service-manager/?ticket_id=${id}`)
            .then((res) => {
                // if (res.data.data[0].ticket_status_for_service_manager === "close") {

                //setAsset1(res?.data?.data?.find((i)=>i.id === Number(id))?.assets)
                setAsset1(res?.data?.data?.assets)
                console.log(res?.data?.data, "pks1")
                // }
            })
            .catch((e) => {
                console.log(e, "rejected")
            })
    }
    useEffect(() => {
        AssetReqireForService()
    }, [])
    console.log(asset1, "bbb")
    const initialValues = {
        date: "",
        ticket_id: "",
        request_quantity: "",
        remarks: "",
        asset_request_id: ""
    };
    const { handleSubmit, handleChange, values } = useFormik({
        initialValues: initialValues,

        onSubmit: () => {

            const reqBody = {

                date: values.date,
                ticket_id: id,
                request_quantity: 1,
                remarks: "remarks",
                asset_request_id: [23,24],
            };
            axiosInstance
                .post("support_page/asset-request-accept-by-service-manager-api-of-bhaaraterp", reqBody)
                .then((res) => {
                    console.log(res)
                    console.log(reqBody, "aaaa")
                    res.data.response_code === 200 && toast.success("Assets Approved");
                })
        },
    });
    return (
        <>
            <div className='flex flex-col  justify-center items-center p-2 p-x-2 border-2 '>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Asset Id</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Asset Name</TableCell>

                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {asset1?.map((row) => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell component="th" align='center' scope="row">
                                                {row.asset_id}
                                            </TableCell>
                                            <TableCell component="th" align='center' scope="row">
                                                {row.asset_name}
                                            </TableCell>
                                            <TableCell align="center"><p className=''>{row.request_date}</p></TableCell>
                                            <TableCell align="center"><p className=''>{row.revert_date}</p></TableCell>
                                            <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                             rounded-xl text-white font-bold p-2'>{row.remarks}</p></TableCell>
                                            <TableCell align="center"><p className=' rounded-xl text-black p-2'>{row.request_status}
                                            </p></TableCell>
                                        </TableRow>

                                    </>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='flex justify-between p-4'>
                    <form onSubmit={handleSubmit} className='flex gap-4' >
                       
                        <button type='submit'className='border bg-blue-400 text-white p-4 rounded-lg' >Approve</button>
                  </form>
                </div>
            </div>
        </>
    )
}

export default RequireAssetForService
