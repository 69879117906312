export const Tabledata=[
    {
        id:1,
        Ticket:"Service",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:2332,
        status:"Normal"
    },
    {
        id:1,
        Ticket:"Service",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:"#43532",
        status:"Normal"
    },
    {
        id:1,
        Ticket:"Service",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:"#64126",
        status:"Normal"
    },
    {
        id:1,
        Ticket:"Service",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:"#53453",
        status:"Normal"
    },
    {
        id:1,
        Ticket:"SUpport Ticket Demo",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:2332,
        status:"Normal"
    },
    {
        id:1,
        Ticket:"Service",
        Start_date:'09/14/2023',
        resolution_date:" 09/25/09",
        ticket_id:2332,
        status:"Normal"
    }
    ]
    export const TableData1 =[
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Medium"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Medium"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Medium"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Medium"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Medium"   
        }
        
    ]
    export const TableData2=[
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Critical"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Critical"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Critical"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Critical"   
        },
        {
            id:1,
            Ticket:"Support Service Demo",
            Start_date:'09/14/2023',
            resolution_date:" 09/25/09",
            ticket_id:"#53623",
            status:"Critical"   
        },
    ]