import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import React, { useState } from 'react'
import { TableData1 } from '../../FirstTimeFixedRate/TableData'
import OnTimeCloseService from './OnTimeCloseService';
import DelayCloseService from './DelayCloseService';

const ServiceEfficency = () => {
  const [click, setClick] = useState("");
  const handleClick=(data)=>{
    setClick(data)
  }
  return (
    <>
 {/* <div className='flex justify-end p-2 px-4'>
  <input className='p-2 px-2' type='text' placeholder='Search..'/>
 </div> */}

   <div className='flex flex-col   shadow-lg'>

     

                <div className='flex px-4 p-4'><h1 className='text-xl font-serif text-[#86048A] font-bold '>Service Efficency</h1></div>
                <div className='flex justify-between p-2 px-4 bg-gradient-to-r from-[#86048A] t0-[#CB3C68]'>
                 
                <div className=''>
                <button onClick={()=> handleClick("OnTimeCloseService")}
                className={`${
                  click === "OnTimeCloseService" ? "!text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
              OnTime Close Service</button>
                </div>

                <div className=''>
                <button onClick={()=> handleClick("DelayCloseService")}
                className={`${
                  click === "DelayCloseService" ? "!text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
              Delay Close Service</button>
                </div>
               
                </div>
                <div className='mt-5'>
                {click === "OnTimeCloseService" && <OnTimeCloseService/>}
                {click === "DelayCloseService" && <DelayCloseService/>}

                </div>
                
            </div>
        
    </>
  )
}

export default ServiceEfficency