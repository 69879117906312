import { MenuItem, TextField } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Ticket = () => {
       


  const Toolkit = [
    {
      value: "Nut Driver",
    },
    {
      value: "Adjustable wrench ",
    },
    {
      value: "Drill or driver",
    },
    {
      value: "Multimeter",
    },
    {
      value: "Needle-nose pliers",
    },
    {
      value: "Voltage taster",
    },
  ];
  const image = [
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
  ];
  return (
    <>
      <div>
        <div className="text-3xl font-bold text-[#86048A]">Ticket Details</div>
        <div className="p-5 shadow-sm shadow-slate-500">
          <div className="grid grid-cols-3 pt-4 gap-8 ">
            <div>
              <div className="flex flex-col gap-4">
                <TextField label="Name" focused />
                <TextField label="State" focused />
                <TextField label="Pincode" focused />
                <TextField label="Start Date" focused />
                <TextField label="Issue Type" focused />
                <TextField label="Attach Photo" type="file" focused />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <TextField label="Mobile" focused />
              <TextField label="City" focused />
              <TextField label="Address" focused />
              <TextField label="Resolution Date" focused />
              <TextField label="Ticket Type" focused />
            </div>
            <div className="flex flex-col gap-4">
              <TextField label="Email" focused />
              <TextField label="Ticket ID" focused />

              <TextField
                label="Tool Kit"
                focused
                select
                defaultValue="Nut Driver"
              >
                {Toolkit.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField label="Product Name" focused />
              <TextField label="Service Manager Assign Date" focused />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 pt-2">
            {image.map((i) => (
              <div>
                <img src={i.image1} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticket;
