export const API_URLS = {
  ServiceQueue: "support_page/service-engineer-ticket-list/",
  OpenServiceRequest: "support_page/service-engineer-ticket-list/",
  //LoginUrl:"support_page/support-login/",
  Ticketno: "support_page/ticket-details/?ticket_id=4",
  Ticketid: "support_page/ticket-details/?ticket_id=4",
  
  ServiceEngineerRequest:"support_page/service-engineer-ticket-list/",
  ManegerOverAllServiceRequest:"support_page/service-manager-ticket-list/",
  LoginUrl: "support_page/support-login/",
  EngineerList: "support_page/service-engineer-list/",
  AssetRequestM:"support_page/asset-request-list-of-service-manager/",
  Assigntask:"support_page/assign-ticket-to-engineer/",
  ServiceEfficency:"support_page/ticket-list-for-efficiency-data-api-of-service-manager/"
  //AssetRequestNew:"support_page/asset-request-list-of-service-manager/",
};
