import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../config/axios'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import CustomInput from '../../../CustomInput'

const RequireToolsForService = ({id}) => {
    const [assetTools,setAssetTools]=useState([])
  const AssetToolsReqireForService=()=>{
      axiosInstance.get(`support_page/ticket-asset-request-details-of-service-manager/?ticket_id=${id}`)
          .then((res) => {
              // if (res.data.data[0].ticket_status_for_service_manager === "close") {
                
              setAssetTools(res?.data?.data?.tools)
              console.log(res,"hhhhh")
                  
                  
              // }
          })
          .catch((e) => {
              console.log(e, "rejected")
          })
  }
  useEffect(()=>{
    AssetToolsReqireForService()
  },[])
  console.log(assetTools,"fff")
  
  const initialValues = {
    date: "",
    ticket_id: "",
    request_quantity: "",
    remarks: "",
    asset_request_id: ""
  };
  
  const {handleSubmit,handleChange,values} = useFormik({
    initialValues: initialValues,

    onSubmit: () => {

        // const array = 
        // assetTools.map((i)=>{
        //     return {
        //         date:assetTools[0]?.date,
        //         ticket_id: assetTools[0]?.ticket_id,      
        //         request_quantity: assetTools[0]?.request_quantity,
        //         remarks:assetTools[0]?.remarks,
        //         asset_request_id:assetTools[0]?.asset_request_id,
        //     }
        // })
  
        
      const reqBody = {

        date:values.date,
        ticket_id: id,      
        request_quantity:1,
        remarks:"remarks",
        asset_request_id:23,
      };
      axiosInstance
        .post("support_page/asset-request-accept-by-service-manager-api-of-bhaaraterp", reqBody)       
        .then((res) => {
          console.log(res)
          console.log(reqBody,"aaaa")
          res.data.response_code===200 && toast.success("Assets Approved");
        })
    },
  });
  return (
    <>
    
    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Tool Id</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Tool Name</TableCell>

                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetTools?.map((row)=>{
                                return(
                                    <>
                                   <TableRow>
                                       
                                          
                                        <TableCell component="th" align='center' scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell component="th" align='center' scope="row">
                                            {row.tool_name}
                                        </TableCell>

                                        <TableCell align="center"><p className=''>{row.request_date}</p></TableCell>
                                        <TableCell align="center"><p className=''>{row.request_date}</p></TableCell>
                                        <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                        rounded-xl text-white font-bold p-2'>{row.remarks}</p></TableCell>
                                        <TableCell align="center"><p className=' rounded-xl text-black p-2'>{row.request_status}
                                        </p></TableCell>
                                    </TableRow>
                                     
                                   </>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='flex justify-end p-4'>
                    <form onSubmit={handleSubmit} className='flex gap-4' >
                    <CustomInput
                            type="date"
                            size="large"
                            id="date"
                            name="date"
                            
                            value={values?.date}
                            placeholder="Enter Date"
                            onChange={handleChange}
                        />
                    
                    <button type='submit'className='border bg-blue-400 text-white p-4 rounded-lg' >Approve</button>
                    </form>
                </div>
    
    </>
  )
}

export default RequireToolsForService