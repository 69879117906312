import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from '@mui/material/MenuItem';
import axiosInstance from "../../config/axios";
import { API_URLS } from "../../config/apiUrls";
import axios from "axios";
import { Link } from "react-router-dom";


const ServiceQueue = () => {

  const [Status, setStatus] = React.useState('');
  const [data, setdata] = useState([]);


  // console.log(data, "e");
  const handleChange = (event) => {
      setStatus(event.target.value);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    const ServiceQueue = () => {
      axiosInstance
          .get(API_URLS.ServiceQueue)
          .then((response) => {
              setdata(response?.data?.data)
              // console.log(response,"hello")
          })
          .catch((error) => {
              console.log(error);
          });
  }


  useEffect(() => {
    ServiceQueue()
}, [])
    
  return (
    <>

<div className="">
<div className="grid grid-cols-3 p-5">
          <div className="col-start-3 grid grid-cols-4 gap-2">
            <div className=" flex col-span-2 ">
              <input
                type="text"
                className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
                placeholder="Search..."
              />
              <button className=" leneargradient p-2 rounded-r-3xl">
                <SearchIcon className="text-white  " fontSize="large" />
              </button>
            </div>
            <div className="flex">
              {" "}
              <button
                className="w-10/12 leneargradient rounded-l-3xl p-2 text-xl text-white"
                onClick={handleClick}
              >
                Filter
              </button>
              <button className="leneargradient rounded-r-3xl p-1">
                <FilterAltIcon className="text-white" />
              </button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="!w-96"
              >
                <MenuItem onClick={handleClose} className=" !w-36">
                  Date
                </MenuItem>
                <MenuItem onClick={handleClose}>Status</MenuItem>
              </Menu>
            </div>

          </div>
        </div>
    <div className="">
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead className="table1 ">
            <TableRow>

              <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">Start Date</TableCell>
             
              <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">Resolution Date</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">Ticket ID</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">Ticket Type</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-blue-600 
              !text-center">
                Rejected
              </TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-blue-600 !text-center">
                Accepted
              </TableCell>
            
            </TableRow>
          </TableHead>
          <TableHead>

            {data?.map((i) => (

              <TableRow 
              key={i.name}
            >
            
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">{i.date}
              </TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">{i.ticket_closing_date}</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">

                <Link to="/ticket_no">
                <p className="p-2 text-white bg-gradient-to-r from-purple-800
                 to-pink-700 rounded text-center">{i.ticket_no}</p>
                </Link>
                </TableCell>
              <TableCell className=" !text-center border-r border-black border-opacity-30 !text-[#8392AB]">{i.ticket_service_type}</TableCell>


              <TableCell className=" border-r border-black border-opacity-30 !text-[#8392AB] !text-center">
         <DeleteForeverIcon className="text-red-700"/>
              </TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-green-600 !text-center">
              <input type="checkbox" className="w-10 h-5"/>
              </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  </div>
    </>

  )
}

export default ServiceQueue