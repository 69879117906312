import { Box, Modal } from "@mui/material";
import classNames from "classnames";
import React from "react";

const CustomModal = ({ children, className, open, onClose }) => {
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          className={classNames(
            "absolute rounded-lg bg-white border-opacity-30 border border-white top-1/2 left-1/2 outline-none -translate-x-1/2 overflow-y-auto -translate-y-1/2 w-1/2 shadow-md",
            className
          )}
        >
          <div className="backdrop-blur-xl p-8">{children}</div>
        </Box>
      </Modal>
    </>
  );
};
export default CustomModal;
