import Diversity2Icon from "@mui/icons-material/Diversity2";

export const navItems1 = [
  {
    id: 1,
    navLink: "/Overall_request",
    navItem: "Over All Service Request",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 2,
    navLink: "/service_engineer",
    navItem: "Service Engineers Utilization",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 3,
    navLink: "/service_team_performance",
    navItem: "Service Team Performance",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 4,
    navLink: "/sla_compliance_report",
    navItem: "SLA Compliance Report",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 5,
    navLink: "/customer_satisfaction_index",
    navItem: "Customer Satisfaction Index",
    navIcon: <Diversity2Icon />,
  },

  {
    id: 6,
    navLink: "/service_cost_expenses",
    navItem: "Service Cost & Expenses",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 6,
    navLink: "/service_efficency",
    navItem: "Service Efficency",
    navIcon: <Diversity2Icon />,
  },
  
 
  {
    id: 9,
    navLink: "/asset_request_m",
    navItem: "Asset RequestM",
    navIcon: <Diversity2Icon />,
  },
];
