import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FeedBackTable } from './FeedBackTable';

const Customer_feedback = () => {
  return (
    <>
      <div className='flex flex-col p-4 '>
        <div className=''>
          <h1 className='flex px-4 p-4 text-xl font-bold text-[#86048A]'>Customer FeedBack And Rating</h1>
        </div>
        <div className=''>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-label="simple table">
              <TableHead>
                <TableRow className=''>
                  <TableCell className='table1 !text-gray-600  !font-semibold !text-sm' align='left'>Service</TableCell>
                  <TableCell className='table1  !text-gray-600 !font-semibold !text-sm' align='left'>Customer Name</TableCell>
                  <TableCell className='table1  !text-gray-600 !font-semibold !text-sm' align='left'>Start Date</TableCell>
                  <TableCell className='table1  !text-gray-600 !font-semibold !text-sm' align='left'>Resolution Date</TableCell>
                  <TableCell className='table1  !text-gray-600 !font-semibold !text-sm' align='left'>Ticket Id</TableCell>
                  <TableCell className='table1 !text-gray-600 !font-semibold !text-sm' align='left'>Status</TableCell>
                  <TableCell className='table1 !text-gray-600 !font-semibold !text-sm' align='left'>Rating</TableCell>
                  <TableCell className='table1 !text-gray-600 !font-semibold !text-sm' align='left'>FeedBack</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FeedBackTable.map((row) => (
                  <TableRow
                    // key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className="!shadow-none"
                  >
                   <TableCell className='!text-sm !items-center' component="th" scope="row">
                     {row.Service}
                    </TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left">{row.Customer_Name}</TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left">{row.Start_date}</TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left">{row.resolution_date}</TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left"><p className='bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] rounded-xl text-white font-bold p-1 px-2'>{row.ticket_id}</p></TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center' align="left">{row.status}</TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left">{row.Rating}</TableCell>
                    <TableCell className=' text-[#8392AB] !text-sm !items-center ' align="left">{row.Feedback}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>
    </>
  )
}

export default Customer_feedback