import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const Delaytime = () => {
  const rows = [
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
    {
      Ticket: "Support Ticket Demo 1",
      Engineer: "Rohit Sharma",
      Date: "04/08/2023",
      completion: "08/08/2023",

      ticketid: "#313566",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-3">
        <div className="grid grid-cols-2 col-start-3">
          <div className=" flex col-start-2  ">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>
        </div>
      </div>
      <div className="text-2xl font-bold text-[#86048A] !p-2">
        Delay Tickets
      </div>
      <div className="asset1 grid grid-cols-6 p-4">
        <div className="text-white text-xl font-bold">Tickets</div>
        <div className="text-white text-xl font-bold">24</div>
        <div className="text-white text-xl text-right font-bold lg:col-start-5">
          Performance
        </div>
        <div className="text-white text-xl text-right font-bold">24%</div>
      </div>
      <div className="">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="!p-2"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Ticket
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Engineer Name
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Date of Issue
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Date of Completion
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Ticket Id
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Start}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Ticket}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Engineer}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.Date}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.completion}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    <button className="border p-2 button1 rounded-2xl text-white font-bold text-base">
                      {row.ticketid}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Delaytime;
