import { Avatar, Chip, IconButton } from "@mui/material";
import Sidebar from "../Shared/Sidebar";
import { Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import { useState } from "react";

const Layout = ({ component, navItem, id }) => {
  const user = JSON.parse(localStorage.getItem("hrm_user"));
  const [id1, setid1] = useState(true);
  const navigate = useNavigate();
  const Role = localStorage.getItem("role")
  console.log(Role)
  const logOutFun = () => {
    navigate("/");
    localStorage.clear();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex">
      <div>
        <Sidebar id1={id1} />
      </div>
      <div className="flex flex-col gap-4 bg-gray-100 w-full p-10">
        <div className="flex w-full">
          <div className="flex w-full mb-4 items-center rounded justify-between">
            <p className="text-xl font-semibold">{navItem}</p>

            <div className="flex items-center gap-5">
              <span className="border rounded px-3 py-2">
                <Search />
                <input
                  className="outline-none bg-gray-100"
                  type="text"
                  placeholder="Search..."
                />
              </span>
              <span className="flex gap-5">
                <AccountCircleIcon onClick={handleClick}
                  className="text-gray-500 mt-1"
                  style={{ fontSize: "30px" }}
                />
               
                <BrightnessHighIcon
                  className="text-gray-500 mt-1"
                  style={{ fontSize: "30px" }}
                />
                <NotificationsIcon
                  className="text-gray-500 mt-1"
                  style={{ fontSize: "30px" }}
                />
              </span>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={logOutFun}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className=" flex gap-48">
          {
            Role === "service manager" && <span
              onClick={() => setid1(false)}
              className=" flex py-2 px-5 border-2 gap-3 items-center rounded-md 
            bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] border-rose-700"
            >
              <span className="p-1 bg-white rounded shadow">
                <img
                  src="https://cdn.iconscout.com/icon/premium/png-512-thumb/technician-3355227-2793603.png?f=avif&w=256"
                  alt=""
                  className="h-10 w-10 "
                />
              </span>
              <p className="text-xl font-bold text-white">Service Manager</p>
            </span>
          }


          {
            Role === "Service Engineer" && <span
              onClick={() => setid1(true)}
              className=" flex py-2 px-5 border-2 gap-3 items-center rounded-md 
            bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] border-rose-700"
            >
              <span className="p-1 bg-white rounded shadow">
                <img
                  src="https://cdn.iconscout.com/icon/premium/png-512-thumb/technician-3355227-2793603.png?f=avif&w=256"
                  alt=""
                  className="h-10 w-10 "
                />
              </span>
              <p className="text-xl font-bold text-white">Serivce Engineer</p>
            </span>
          }



        </div>
        <div className=" ">{component}</div>
        {/* <div className="flex flex-col bg-white shadow  rounded-lg gap-2 p-1">
          
        </div> */}
      </div>
    </div>
  );
};

export default Layout;
