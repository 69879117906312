import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import slide from "../../../../Assets/Slide.png";
const Closetime = () => {
  return (
    <>
      <div className="grid grid-cols-3">
        <div className="grid grid-cols-2 col-start-3">
          <div className=" flex col-start-2  ">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>
        </div>
      </div>
      <div className="text-2xl font-bold text-[#86048A] !p-2">
        Cancel Tickets
      </div>
      <div className="asset1 grid grid-cols-6 p-4">
        <div className="text-white text-xl font-bold">Tickets</div>
        <div className="text-white text-xl font-bold">0</div>
        <div className="text-white text-xl text-right font-bold lg:col-start-5">
          Performance
        </div>
        <div className="text-white text-xl text-right font-bold">0%</div>
      </div>
      <div className="justify-center flex shadow-md shadow-slate-400">
        <img src={slide} alt="" />
      </div>
    </>
  );
};

export default Closetime;
