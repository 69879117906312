export const Tabledata = [
    {
      Services:"Air Conditioner",
      TicketID: "#3135116",
  
      StartDate: "04/08/2023",
      ResolutionDate:"05/05/2023",
     
      Subscription:"1 Month",
      Tickettype:"Service",
      Status: "Open",
      
    },
    {
        Services:"Deep Freezer",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
      
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
      
  
    },

    {
        Services:"Mixer Grinders",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
      
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
  
    },
    {
        Services:"Oven & Heaters",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
     
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
      
    },
    {
        Services:"Vaccum Cleaner",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
     
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
     
    },
    {
        Services:"RO water purifier",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
     
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
     
    },

    {
        Services:"Laptops",
        TicketID: "#3135116",
  
        StartDate: "04/08/2023",
        ResolutionDate:"05/05/2023",
     
        Subscription:"1 Month",
        Tickettype:"Service",
        Status: "Open",
     
    },
    
  ];
  