import AssignedTask from "../Components/AssignedTask";
import Ticket from "../Components/AssignedTask/Ticket";
import Customer_feedback from "../Components/CustomerFeedBack";
import FirstTimeFixed from "../Components/FirstTimeFixedRate";
import Critical from "../Components/FirstTimeFixedRate/Critical";
import Medium from "../Components/FirstTimeFixedRate/Medium";
import Normal from "../Components/FirstTimeFixedRate/Normal";
import OpenServiceRequest from "../Components/OpenServiceRequest";
import Ticketid from "../Components/OpenServiceRequest/Ticketid";
import SLACompliance from "../Components/SLA Compliance";
import Backlog from "../Components/ServiceBacklog";
import Sparepart from "../Components/Sparepart";
import Asset from "../Components/Sparepart/Sparepartasset";
import Test from "../Components/test";
import Tab1 from "../Components/Categories/Tabs/Tab1";
import ServiceQueue from "../Components/ServiceQueue";
import Averagetime from "../Components/Averagetime";
import Overall from "../Components/Manager/Overall";
import Utilization from "../Components/Manager/Utilization";
import SLareport from "../Components/Manager/SLareport";
import Customer from "../Components/Manager/Customer";
import Repeattime from "../Components/Manager/Performance/Repeat";
import Delaytime from "../Components/Manager/Performance/Delay";
import Closetime from "../Components/Manager/Performance/Closetime";
import Performance from "../Components/Manager/Performance";
import Ticketclose from "../Components/Manager/Performance/Ticketclose";
import ServiceEfficency from "../Components/Manager/ServiceEfficiency";
import AssetRequset from "../Components/Manager/AssetRequest";

// );
export const routes = [
  {
    id: 1,
    path: "/open_service-request",
    component: <OpenServiceRequest />,
    navItem: "OpenServiceRequest",
  },
  {
    id: 2,
    path: "/assigned_task",
    component: <AssignedTask />,
    navItem: "AssignedTask",
  },
  {
    id: 3,
    path: "/firsttimefixedrate",
    component: <FirstTimeFixed />,
    navItem: "FirstTimeFixedRate",
  },
  {
    id: 4,
    path: "/normal",
    component: <Normal />,
    navItem: "Normaltickets",
  },
  {
    id: 5,
    path: "/medium",
    component: <Medium />,
    navItem: "Mediumtickets",
  },
  {
    id: 6,
    path: "/critical",
    component: <Critical />,
    navItem: "Criticaltickets",
  },
  {
    id: 7,
    path: "/SLA_ompliance",
    component: <SLACompliance />,
    navItem: "SLACompliance",
  },
  {
    id: 8,
    path: "/Customer_feedback",
    component: <Customer_feedback />,
    navItem: "CustomerFeedbackandrating",
  },
  {
    id: 8,
    path: "/test",
    component: <Test />,
    navItem: "Test",
  },

  {
    id: 9,
    path: "/service_backlog",
    component: <Backlog />,
    navItem: "Service Backlog",
  },
  {
    id: 10,
    path: "/ticket_details",
    component: <Ticket />,
    navItem: "",
  },
  {
    id: 11,
    path: "/Pending_spare",
    component: <Sparepart />,
    navItem: "Pending Spare Parts",
  },
  {
    id: 12,
    path: "/asset_pending",
    component: <Asset />,
    navItem: "Pending Spare Parts",
  },
  {
    id: 13,
    path: "/ticket_id",
    component: <Ticketid />,
    navItem: "Ticket details",
  },

  { id: 14, path: "/tab1", component: <Tab1 />, navItem: "AssignedTask" },
  {
    id: 15,
    path: "/servicequeue",
    component: <ServiceQueue />,
    navItem: "AssignedTask",
  },

  {
    id: 16,
    path: "/averagetime",
    component: <Averagetime />,
    navItem: "AssignedTask",
  },
  {
    id: 17,
    path: "/Overall_request",

    component: <Overall />,
    navItem: "Over All Service Request",
  },
  {
    id: 18,
    path: "/service_engineer",
    component: <Utilization />,
    navItem: "Service Engineers Utilization",
  },
  {
    id: 19,
    path: "/service_team_performance",
    component: <Performance />,
    navItem: "Service Team Performance",
  },
  {
    id: 20,
    path: "/ticket_close",
    component: <Ticketclose />,
    navItem: "Service Team Performance",
  },
  {
    id: 21,
    path: "/repeat_time",
    component: <Repeattime />,
    navItem: "Service Team Performance",
  },
  {
    id: 22,
    path: "/delay_time",
    component: <Delaytime />,
    navItem: "Service Team Performance",
  },
  {
    id: 23,
    path: "/close_time",
    component: <Closetime />,
    navItem: "Service Team Performance",
  },
  {
    id: 24,
    path: "/sla_compliance_report",
    component: <SLareport />,
    navItem: "SLA Compliance Report",
  },
  {
    id: 25,
    path: "/customer_satisfaction_index",
    component: <Customer />,
    navItem: "Customer Satisfaction Index",
  },
  {
    id: 26,
    path: "/sla_compliance_report",
    component: <SLareport />,
    navItem: "SLA Compliance Report",
  },
  {
    id: 27,
    path: "/service_efficency",
    component: <ServiceEfficency/>,
    navItem: "Service Efficency",
  },

  {
    id: 28,
    path: "/asset_request_m",
    component: <AssetRequset/>,
    navItem: "Asset Request",
  },

];
