import Rating from '@mui/material/Rating';
export const FeedBackTable = [

    {
        id:1,
        Service:"Air Conditioner",
        Customer_Name:"Rakesh Sharma",
        Ticket: "SUpport Ticket Demo",
        Start_date: '09/14/2023',
        resolution_date: " 09/25/09",
        ticket_id: 2332,
        status: "open",
        Rating:<Rating name="read-only" value={5} readOnly />,
        Feedback:"Very Fast/Impressive Service"
    },
    {
      id:1,
        Service:"Air Conditioner",
        Customer_Name:"Rakesh Sharma",
        Ticket: "SUpport Ticket Demo",
        Start_date: '09/14/2023',
        resolution_date: " 09/25/09",
        ticket_id: 2332,
        status: "open",
        Rating:<Rating name="read-only" value={5} readOnly />,
        Feedback:"Very Fast/Impressive Service"
    },
    {
        id:1,
        Service:"Air Conditioner",
        Customer_Name:"Rakesh Sharma",
        Ticket: "SUpport Ticket Demo",
        Start_date: '09/14/2023',
        resolution_date: " 09/25/09",
        ticket_id: 2332,
        status: "open",
        Rating:<Rating name="read-only" value={5} readOnly />,
        Feedback:"Very Fast/Impressive Service"
    },
    {
        id:1,
        Service:"Air Conditioner",
        Customer_Name:"Rakesh Sharma",
        Ticket: "SUpport Ticket Demo",
        Start_date: '09/14/2023',
        resolution_date: " 09/25/09",
        ticket_id: 2332,
        status: "open",
        Rating:<Rating name="read-only" value={5} readOnly />,
        Feedback:"Very Fast/Impressive Service"
    },
    {
        id:1,
        Service:"Air Conditioner",
        Customer_Name:"Rakesh Sharma",
        Ticket: "SUpport Ticket Demo",
        Start_date: '09/14/2023',
        resolution_date: " 09/25/09",
        ticket_id: 2332,
        status: "open",
        Rating:<Rating name="read-only" value={5} readOnly />,
        Feedback:"Very Fast/Impressive Service"
    },


]