import { Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../config/axios'
import { API_URLS } from '../../../config/apiUrls'

import { Close } from '@mui/icons-material'

import CustomModal from '../../CustomModal'
import RequireAssetForService from './RequireAssetforService'
import RequireToolsForService from './RequireToolsForService'
import ProcuringToolsForService from './ProcuringToolsForService'

const AssetRequset = () => {

  const [asset, setAsset] = useState([])
  const [open, setOpen] = React.useState(false);
  const [click, setClick] = useState("");
  const [id3,setId3]=useState()

  console.log(id3,"lll")
  const handleClick = (data) => {
    setClick(data)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
  };
  const AssetRequestM = () => {
    axiosInstance
      .get(API_URLS.AssetRequestM)
      .then((response) => {
        setAsset(response.data.data);
        console.log(response.data.data)
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    AssetRequestM();
  }, []);
  return (
    <><div className='flex flex-col px-4 p-4  shadow-lg'>
      <div className='flex px-4 p-4'><h1 className='text-xl font-serif text-[#86048A] font-bold px-4'>Asset Request</h1></div>
      <div className='px-4 p-4'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Ticket</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">All Assets</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {asset.map((row) => (
                <TableRow
                  key={row.id}
                >
                  <TableCell component="th" align='center' scope="row">
                    {row.product_issue}
                  </TableCell>
                  <TableCell align="center"><p className=''>{row.date}</p></TableCell>
                  <TableCell align="center"><p className=''>{row.ticket_closing_date}</p></TableCell>
                  <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                        rounded-xl text-white font-bold p-2'>{row.ticket_no}</p></TableCell>
                  <TableCell align="center"><p className=' rounded-xl text-black p-2'>{row.ticket_status_for_service_engineer}
                  </p></TableCell>

                  <TableCell align="center"><p className=''><Button onClick={()=>{
                    handleClickOpen()
                    setId3(row.id)}
                    }>Check Assets</Button></p></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className=''></div>
      </div>
      <div>

        <CustomModal
          open={open}
          onClose={handleClose}
          className="!h-4/5 !overflow-auto"
        >
          <div className="flex justify-between h-fit px-4 pb-4">
            <h3 className="text-xl">Asset Request For Engineer</h3>

            <Close onClick={handleClose} className="!cursor-pointer" />
          </div>

          <Divider />
          <div className="flex flex-col justify-center items-center gap-3 pt-2">
            <div className='flex gap-4 p-4 px-4'>
              <div className='bg-gradient-to-r from-[#86048A] '>
                <button onClick={() => handleClick("RequireAssetForService")}
                  className={`${click === "RequireAssetForService" ? "text-purple-700  border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-56 h-28 p-3 border-2 `}>
               Require Assets For Service</button>
              </div>
              <div className='bg-gradient-to-r from-[#86048A] '>
                <button onClick={() => handleClick("RequireToolsForService")}
                  className={`${click === "RequireToolsForService" ? "text-purple-700  border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-56 h-28 p-3 border-2 `}>
                 Assets Tools For Service</button>
              </div>

              <div className='bg-gradient-to-r from-[#86048A] '>
                <button onClick={() => handleClick("ProcuringToolsForService")}
                  className={`${click === "ProcuringToolsForService" ? "text-purple-700  border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-56 h-28 p-3 border-2 `}>
                  Procuring Tools For Service</button>
              </div>

            </div>

          </div>

          <div className='mt-5'>
                {click === "RequireAssetForService" && <RequireAssetForService id={id3}/>}
                {click === "RequireToolsForService" && <RequireToolsForService id={id3}/>}
                {click === "ProcuringToolsForService" && <ProcuringToolsForService id={id3}/>}

                </div>

        </CustomModal>

      </div>
    </div>
    </>
  )
}

export default AssetRequset