import Diversity2Icon from "@mui/icons-material/Diversity2";

export const navItems = [
  {
    id: 1,
    navLink: "/open_service-request",
    navItem: "Open Service Request",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 2,
    navLink: "/assigned_task",
    navItem: "Assigned Task",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 2,
    navLink: "/servicequeue",
    navItem: "Service Queue",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 4,
    navLink: "/SLA_ompliance",
    navItem: "SLA Compliance",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 5,
    navLink: "/Pending_spare",
    navItem: "Pending Spare Parts",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 6,
    navLink: "/averagetime",
    navItem: "Average Respanse Time",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 7,
    navLink: "/firsttimefixedrate",
    navItem: "First Time Fix Rate",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 8,
    navLink: "/service_backlog",
    navItem: "Service Backlog",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 9,
    navLink: "/tab1",
    navItem: "Serivce Request Categories",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 10,
    navLink: "/Customer_feedback",
    navItem: "Customer FeedBack & Rating",
    navIcon: <Diversity2Icon />,
  },
  {
    id: 11,
    navLink: "",
    navItem: "Asset Request",
    navIcon: <Diversity2Icon />,
  },
];
