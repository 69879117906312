import { useFormik } from "formik";
import React, { useState } from "react";
import Tilt from "react-parallax-tilt";


import { useNavigate } from "react-router-dom";
import slide from "../Assets/Slide.png";
import bhaaratCrm from "../Assets/capture.jpg";
import { CustomButton } from "../Shared/CustomButton";

import axiosInstance from "../config/axios";
import { API_URLS } from "../config/apiUrls";

const LogIn = () => {
  const isLogined = localStorage.getItem("support_page_token");
  const navigate = useNavigate();
  const [registrationToken, setResgistrationToken] = useState("");
  const [deviceId, setDeviceIdData] = useState("");

  // const loginURl="http://192.168.1.90:9093/support_page/support-login/"

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: () => {
      const reqBody = {
        email: formik.values.email,
        password: formik.values.password,
      };
      axiosInstance
        .post(API_URLS.LoginUrl, reqBody)

        .then((res) => {
          localStorage.setItem("Token", res?.data?.data?.token);
          localStorage.setItem("role",res?.data?.data?.role);
          localStorage.setItem("store_id",res?.data?.data?.store_id);
          localStorage.setItem("user_id",res?.data?.data?.user_id)
          console.log(reqBody);
          console.log(res);
        
          res.data.response_code === 200 && navigate("/open_service-request");
        });
    },
  });

  return (
    <>
      <div
        className="h-screen w-screen relative overflow-hidden flex flex-col justify-center items-center  bg-opacity-10 "
        style={{ backgroundImage: `url(${slide})`, backgroundSize: "cover" }}
      >
        <div className="h-40-r w-40-r bg-gradient-to-r from-green-400 to-blue-500 rounded-full absolute left-2/3 -top-56 animate-pulse"></div>
        <div className="h-40-r w-40-r bg-gradient-to-r from-pink-700 to-purple-500 rounded-full absolute top-96  -left-20 animate-pulse"></div>
        <div className="flex justify-end w-4/5">
          <Tilt>
            <div className="h-35-r w-35-r bg-white  rounded-lg shadow-5xl">
              <form
                className="h-full flex flex-col justify-evenly items-center bg-white rounded-2xl w-full"
                onSubmit={formik.handleSubmit}
              >
                <img className="Capture w-48" src={bhaaratCrm} alt="" />
                <div className="">
                  <p className="text-black text-center font-bold text-xl tracking-wider">
                    Log In
                  </p>
                  <p className="text-xs hover:underline text-gray-500">
                    Login now for manage your customers
                  </p>
                </div>
                <input
                  formik={formik}
                  type="text"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Enter your email here"
                  className="focus:outline-none bg-[#E8F0FE] rounded-lg px-3 py-2 border border-r-0 border-t-0 border-l-0 tracking-wider"
                />

                <input
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder="Enter your password here"
                  className="focus:outline-none bg-[#E8F0FE] rounded-lg px-3 py-2 border border-r-0 border-t-0 border-l-0 tracking-wider"
                />

                <div className="flex gap-4 justify-between items-center">
                  <p
                    className="hover:underline cursor-pointer text-gray-500 hover:text-purple-600"
                    onClick={() => navigate("/forget-password")}
                  >
                    Forget Password?
                  </p>
                  <CustomButton
                    type="submit"
                    size="large"
                    className="cursor-pointer bg-gradient-to-r from-[#8B39CD] to-rose-500 !rounded-lg !px-10 py-2 bg-white bg-opacity-50 hover:bg-opacity-80"
                  >
                    Submit
                  </CustomButton>
                </div>
              </form>
            </div>
          </Tilt>
        </div>
      </div>
    </>
  );
};

export default LogIn;
