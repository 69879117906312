import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./Shared/Login.js";
import Layout from "./Layout";
import PageNotFound from "./Components/PageNotFound";
import { routes } from "./Routes";

function App() {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<LogIn />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="*" element={<PageNotFound />} />
          {routes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <Layout
                    id={route.id}
                    navLink={route.path}
                    navItem={route.navItem}
                    component={route.component}
                  />
                }
              ></Route>
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
