import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
const Sparepart = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  function createData(Start, Resolution, Ticket, status, spare, Asset) {
    return { Start, Resolution, Ticket, status, spare, Asset };
  }
  const rows = [
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
  ];
  const navigate = useNavigate();

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 p-5">
        <div className="lg:col-start-3 grid grid-cols-6 gap-2">
          <div className=" flex col-span-3">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>

          <div className="flex  col-span-2 ">
            {" "}
            <button
              className="w-10/12 leneargradient rounded-l-3xl p-2 text-xl text-white"
              onClick={handleClick1}
              id="basic-button"
              aria-controls={open1 ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open1 ? "true" : undefined}
            >
              Filter
            </button>
            <button className="leneargradient rounded-r-3xl p-2">
              <FilterAltIcon className="text-white" />
            </button>
            <Menu
              open={open1}
              onClose={handleClose1}
              id="basic-menu"
              anchorEl={anchorEl1}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="!w-36" onClick={handleClose1}>
                Daily
              </MenuItem>
              <MenuItem onClick={handleClose1} className="!w-24">
                Weekly
              </MenuItem>
              <MenuItem onClick={handleClose1}>Monthly</MenuItem>
            </Menu>
          </div>

          <div className="">
            <button
              onClick={() => {
                navigate("/asset_pending");
              }}
              className="bg-blue-600 p-2 rounded-full text-center"
            >
              <AddIcon className="text-white " fontSize="large" />
            </button>
          </div>
        </div>
      </div>

      <div className="text-2xl font-bold text-[#86048A]">
        Pending Spare Parts{" "}
      </div>
      <TableContainer component={Paper} className="">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="!text-[#5E697B] !font-bold !text-lg">
                Start Data
              </TableCell>
              <TableCell
                className="!text-[#5E697B] !font-bold !text-lg"
                align="left"
              >
                Resolution Data
              </TableCell>
              <TableCell
                className="!text-[#5E697B] !font-bold !text-lg"
                align="center"
              >
                Ticket id
              </TableCell>
              <TableCell
                className="!text-[#5E697B] !font-bold !text-lg"
                align="right"
              >
                Status
              </TableCell>
              <TableCell
                className="!text-[#5E697B] !font-bold !text-lg"
                align="right"
              >
                Spare Part Type
              </TableCell>
              <TableCell
                className="!text-[#5E697B] !font-bold !text-lg"
                align="right"
              >
                Asset Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.Start}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell
                  className="!text-[#8392AB] !text-base"
                  component="th"
                  scope="row"
                >
                  {row.Start}
                </TableCell>
                <TableCell className="!text-[#8392AB] !text-base" align="left">
                  {row.Resolution}
                </TableCell>
                <TableCell
                  className="!text-[#8392AB] !text-base"
                  align="center"
                >
                  <button className="border p-2 button1 rounded-2xl text-white font-bold text-base">
                    {row.Ticket}
                  </button>
                </TableCell>
                <TableCell className="!text-[#8392AB] !text-base" align="right">
                  {row.status}
                </TableCell>
                <TableCell className="!text-[#8392AB] !text-base" align="right">
                  {row.spare}
                </TableCell>
                <TableCell align="right">
                  <button
                    className="border p-2 bg-[#8600D8] rounded-2xl text-white font-bold text-base"
                    onClick={handleClick}
                  >
                    {row.Asset}
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="!w-96"
                  >
                    <MenuItem className=" !w-52">
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Adjustable wrench
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Drill or driver
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Screwdriver
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Multimeter
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Nut driver
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Adjustable wrench
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Needle-nose pliers
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Socket or ratcher set
                    </MenuItem>
                    <MenuItem>
                      <input type="checkbox" className="" />
                      &nbsp;&nbsp; Voltage tester
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Sparepart;
