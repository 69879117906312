import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axiosInstance from "../../../config/axios";
import { API_URLS } from "../../../config/apiUrls";

const Ticketid = () => {
  const [data, setdata] = useState([]);

  const Toolkit = [
    {
      value: "Nut Driver",
    },
    {
      value: "Adjustable wrench ",
    },
    {
      value: "Drill or driver",
    },
    {
      value: "Multimeter",
    },
    {
      value: "Needle-nose pliers",
    },
    {
      value: "Voltage taster",
    },
  ];
  const image = [
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
    {
      image1:
        "https://images.pexels.com/photos/145683/pexels-photo-145683.jpeg?auto=compress&cs=tinysrgb&w=1600",
    },
  ];

  const TicketDetails = () => {
    axiosInstance
      .get(API_URLS.Ticketid)
      .then((response) => {
        setdata(response);
        console.log(response, "hello");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    TicketDetails();
  }, []);
  console.log(data.data.data.contact_no);
  return (
    <>
      <div>
        <div className="text-3xl font-bold text-[#86048A]">Ticket Details</div>

        {[1].map((i) => {
          return (
            <>
              <div className="grid grid-cols-3 pt-4 gap-8 ">
                <TextField
                  label="Customer Name"
                  focused
                  value={data.data.data.customer_name}
                />
                <TextField label="Date" focused value={data.data.data.date} />
                <TextField
                  label="Ticket-Closing Date"
                  focused
                  value={data.data.data.ticket_closing_date}
                />
                <TextField
                  label="Ticket-no"
                  focused
                  value={data.data.data.ticket_no}
                />
                <TextField
                  label="Warranty-Condition"
                  focused
                  value={data.data.data.warranty_condition}
                />
                <TextField
                  label="Ticket-Service Type"
                  focused
                  value={data.data.data.ticket_service_type}
                />

                <TextField
                  label="Remark"
                  focused
                  value={data.data.data.remarks}
                />
                <TextField
                  label="Customer-Email"
                  focused
                  value={data.data.data.customer_email}
                />
                <TextField
                  label="Father Name"
                  focused
                  value={data.data.data.father_name}
                />
                <TextField
                  label="Pincode"
                  focused
                  value={data.data.data.pin_code}
                />
                <TextField
                  label="Contact-no."
                  focused
                  value={data.data.data.contact_no}
                />
                <TextField
                  label="Bussiness-Type"
                  focused
                  value={data.data.data.bussiness_type}
                />

                <TextField
                  label="Payment"
                  focused
                  value={data.data.data.payment}
                />
                <TextField
                  label="Payable Amount-Add"
                  focused
                  value={data.data.data.payable_amount}
                />
                <TextField
                  label="QR Code"
                  focused
                  value={data.data.data.payment_qr_code}
                />
                <TextField
                  label="Status for Service-Manager"
                  focused
                  value={data.data.data.ticket_status_for_service_manager}
                />
                <TextField
                  label="Ticket Assign Date"
                  focused
                  value={data.data.data.ticket_assign_date}
                />
                <TextField
                  label="Status for Service-Engineer"
                  focused
                  value={data.data.data.ticket_status_for_service_engineer}
                />
              </div>
            </>
          );
        })}
        {/*           
            {data?.data?.data.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))} */}

        <div className="grid grid-cols-4 gap-2 pt-2">
          {image.map((i) => (
            <div>
              <img src={data.data.data.customer_on_site_image} alt="" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Ticketid;
