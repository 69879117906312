import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";

import DoneIcon from "@mui/icons-material/Done";
import {
  Avatar,
  Checkbox,
  Divider,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import axiosInstance from "../../../config/axios";
import { API_URLS } from "../../../config/apiUrls";
import axios from "axios";
import { Formik, useFormik } from "formik";
import ChangeEngineerStatus from "../CustomModal";
import { Navigate, useNavigate } from "react-router-dom";
import CustomModal from "../../CustomModal";
import { Close } from "@mui/icons-material";
import CustomInput from "../../CustomInput";
import MuiSelect from "../../MuiSelect";
import CustomButton from "../../Custombutton";
import { number } from "yup";
import { toast } from "react-toastify";
const Overall = () => {
   
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [open3, setOpen3] = useState(false);
  const [file, setFile] = useState("");
  const [id1, setId] = useState("");
  const [engineerId,setEngineerId]=useState({})
  console.log(id1,"gghhhhhg")
  const handleClose3 = () => {
    setOpen3(false);
  };
  // const handleClick3 = () => {
  //   setOpen3(true);
  // };
  console.log(engineerId.user_id,"jjjjjjjj")
  const handleCloseForm = () => {
    setFile("");
    Formik?.resetForm();
    handleClose3();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  
  const [overAllRequset, setOverAllRequset] = useState([]);
  const OverAllServiceRequset = async () => {
     axiosInstance.get(API_URLS.ManegerOverAllServiceRequest)
     .then((res)=>{
      setOverAllRequset(res);
      console.log(res?.data?.data,"rrrr");
     })
     .catch((e)=>{
      console.log(e)
     })
    }
  

  useEffect(() => {
    OverAllServiceRequset();
  }, []);
  console.log(overAllRequset,"mm");

  const [engineerList, setEngineerList] = useState([]);

  const EngineerList = () => {
    axiosInstance
      .get(API_URLS.EngineerList)
      .then((response) => {
        setEngineerList(response?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    EngineerList();
  }, []);
  console.log(engineerList, "ppp");

  //console.log(engineerList)

  // Assign Ticket To Engineers 
 // const AssignTicket = "http://192.168.1.90:9093/support_page/assign-ticket-to-engineer/"
  const storeid = localStorage.getItem("store_id")
  
  
  const initialValues = {
    store_id: "",
    ticket_id: "",
    user_id: "",
    emp_id: "",
    ticket_closing_date: ""
  };
  const {handleSubmit,handleChange,values} = useFormik({
    initialValues: initialValues,

    onSubmit: () => {
  
      const reqBody = {

        store_id: storeid,
        user_id: engineerId?.user_id,
        ticket_id: id1,
        emp_id: engineerId?.emp_id,
        ticket_closing_date: values.date
      };
      axiosInstance
        .post(API_URLS.Assigntask, reqBody)       
        .then((res) => {
          console.log(res)
          console.log(reqBody,"aaaa")
          res.data.response_code===200 && toast.success("Assign Ticket to engineer");
        })
    },
  });
 
  return (
    <>
      <div className="grid sm:grid-cols-1   lg:grid-cols-4 ">
        <div className="lg:col-start-3 col-span-2 grid grid-cols-9 gap-2  ">
          <div className=" flex   col-span-4">
            <input
              type="text"
              className="leneargradient outline-none  text-white placeholder:text-white text-xl 
              rounded-l-3xl w-10/12 p-2"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="small" />
            </button>
          </div>
          <div className="flex  col-span-2">
            <button
              className="w-10/12 leneargradient rounded-l-3xl  text-xl text-white"
              onClick={handleClick}
            >
              Filter
            </button>
            <button className="leneargradient rounded-r-3xl p-2">
              <FilterAltIcon className="text-white" />
            </button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem className=" !w-36">Daily</MenuItem>
              <MenuItem className=" !w-36 !border-t-2 border">Weekly</MenuItem>
              <MenuItem className=" !w-36">Monthly</MenuItem>
            </Menu>
          </div>
          
       </div>
      </div>

      <div className="pt-2">
        <div className="text-2xl font-bold text-[#86048A] !p-2">
          Overall Service Request{" "}
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="!p-2"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Ticket
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Date
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Currrent Worker
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Current Status
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Ticket id
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Assign ticket
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {overAllRequset?.data?.data?.map((row) => (
                <TableRow
                  key={row.Start}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.product_issue}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.date}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.customer_name}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.ticket_status_for_service_manager}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    <button className="border p-2 button1 rounded-2xl text-white font-bold text-base">
                      {row.ticket_no}
                    </button>
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    <button className="border p-2 button1 rounded-2xl text-white font-bold text-base" onClick={()=>{
                      setOpen3(true)
                      setId(row.id)
                      }}>
                      Assign Enginner
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CustomModal
        open={open3}
        onClose={handleClose3}
        className="!h-4/5 !overflow-auto"
      >
        <div className="flex justify-between h-fit px-4 pb-4">
          <h3 className="text-xl">Assign Ticket To Engineer</h3>
         
          <Close  onClick={handleClose} className="!cursor-pointer"/>
        </div>
        <form
          onSubmit={handleSubmit}
          className="p-2 font-poppins h-auto w-full"
        >
          <Divider />
          <div className="flex flex-col gap-3 pt-2">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-3">
               
                <MuiSelect
                  className="!w-full"
                  labelId="Engineer Name"
                  id="user_id"
                  name="user_id"
                  
                  onChange={(event)=> {handleChange(event.target.value);
                    setEngineerId(event.target.value)}}
                  label="Engineer Name"
                >
                  <MenuItem value="">
                    <em>---Engineer Name---</em>
                  </MenuItem>
                  {engineerList.map((item) => (
                    <MenuItem value={item}>{item?.full_name}</MenuItem>
                  ))}
                </MuiSelect>
            </div>

            <div className="flex justify-end gap-4">
              <CustomButton
               
                variant="contained"
             
                className="bg-gradient-to-r !text-white !capitalize from-gray-500 to-gray-400 !rounded-lg !px-3 !py-2"
                onClick={handleClose}
              >
                Close
              </CustomButton>
              <CustomButton
             
                type="submit"
              >
                Submit
              </CustomButton>
            </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default Overall;
