import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ReactApexChart from "react-apexcharts";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useNavigate } from "react-router-dom";
const Performance = () => {
  const state = {
    wx: [70, 72],
    options: {
      chart: {
        type: "radialBar",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: ["Average range"],
          },
        },
      ],
    },
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="grid grid-cols-3">
        <div className="grid grid-cols-2 col-start-3">
          <div className=" flex col-start-2  ">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>
        </div>
      </div>
      <div className="text-2xl font-bold text-[#86048A] !p-2">
        Service Team Performance
      </div>
      <div className="shadow-slate-400 shadow-md grid grid-cols-2 bg-white">
        <div className="">
          <ReactApexChart
            options={state.options}
            series={state.wx}
            type="donut"
            height={350}
          />
        </div>
        <div className="pt-8  ">
          <div className="grid grid-cols-2 border p-5">
            <div className="">
              <div className="">
                {" "}
                <PanoramaFishEyeIcon className="bg-sky-400 !outline-none border" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 ">
        <div className="grid grid-cols-4 gap-8">
          <div>
            <button
              onClick={() => {
                navigate("/ticket_close");
              }}
              className="performance1 w-full h-full  text-2xl p-8"
            >
              On Time Closed
            </button>
          </div>
          <div className="">
            <button
              onClick={() => {
                navigate("/repeat_time");
              }}
              className="performance1 w-full h-full text-2xl p-8"
            >
              Repeat
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/delay_time");
              }}
              className="performance1  w-full h-full  text-2xl p-8"
            >
              Delay
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/close_time");
              }}
              className="performance1  w-full h-full  text-2xl p-8"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Performance;
