import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import {TableData1} from '../TableData'

const Medium = () => {
    return (
        <>
        
            <div className='flex flex-col   shadow-lg'>
                <div className='flex px-2'><h1 className='text-xl font-serif text-[#86048A] font-bold px-4'>Normal Tickets</h1></div>
                <div className=' p-4'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Ticket</TableCell>
                                    <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                                    <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                                    <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                                    <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {TableData1.map((row) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell component="th" align='center' scope="row">
                                            {row.Ticket}
                                        </TableCell>
                                        <TableCell align="center"><p className=''>{row.Start_date}</p></TableCell>
                                        <TableCell align="center"><p className=''>{row.resolution_date}</p></TableCell>
                                        <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                         rounded-xl text-white font-bold p-2'>{row.ticket_id}</p></TableCell>
                                        <TableCell align="center"><p className='bg-[#FD8F3E] rounded-xl text-white p-2'>{row.status}
                                        </p></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        
        </>
    )
}

export default Medium