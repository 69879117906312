import React from "react";
import Capture from "../../Assets/erp.png";
import { useNavigate } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { navItems } from "../../Mock";
import classNames from "classnames";

import { navItems1 } from "../../Mock1";

const Sidebar = ({ id1 }) => {
  const navigate = useNavigate();

  return (
    <List
      className=" text-white shadow-md !relative first-line:!pb-20 !p-2 min-w-fit
     bg-gradient-to-t from-pink-800
    to-purple-700"
    >
      <ListItem className="!p-5 !border-b !bg-white !rounded-lg">
        <img alt="" className="Capture !w-40" src={Capture} />
      </ListItem>
      <hr className="mt-5" />
      <Divider />
     {localStorage.getItem("role")==="service manager"  ? (
         <div className="!mt-5">
         {navItems1?.map((nav) => {
           return (
             <ListItemButton
               onClick={() => navigate(nav.navLink)}
               className={classNames(
                 "!rounded-lg !p-2 ",
                 window.location.pathname === nav.navLink &&
                   "!bg-white !text-purple-800 font-bold"
               )}
               style={{ fontSize: "14px" }}
             >
               <ListItemIcon
                 className={classNames(
                   "!rounded-lg !p-2 ",
                   window.location.pathname === nav.navLink &&
                     "!bg-white !font-bold !text-[#86048A]"
                 )}
                 style={{ fontSize: "12px" }}
               >
                 {nav.navIcon}
               </ListItemIcon>
               {nav.navItem}
             </ListItemButton>
           );
         })}
       </div>
      ) : (
        <div className="!mt-5">
          {navItems?.map((nav) => {
            return (
              <ListItemButton
                onClick={() => navigate(nav.navLink)}
                className={classNames(
                  "!rounded-lg !p-2 ",
                  window.location.pathname === nav.navLink &&
                    "!bg-white !text-purple-800 font-bold"
                )}
                style={{ fontSize: "14px" }}
              >
                <ListItemIcon
                  className={classNames(
                    "!rounded-lg !p-2 ",
                    window.location.pathname === nav.navLink &&
                      "!bg-white !font-bold !text-[#86048A]"
                  )}
                  style={{ fontSize: "12px" }}
                >
                  {nav.navIcon}
                </ListItemIcon>
                {nav.navItem}
              </ListItemButton>
            );
          })}
        </div>
       
      )}

      <div className=" border-2 bg-cover bg-gradient-to-r from-gray-400 to gray-300 rounded-xl flex flex-col px-5 text-center">
        <span className="p-2 rounded-md ">
          <QuestionMarkIcon className="!text-purple-800 bg-white" />
        </span>
        <p className="text-white font-bold">Need Help ?</p>
        <p className="text-white ">Please Check Our Docs</p>
      </div>
    </List>
  );
};

export default Sidebar;
