import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../config/axios'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

const ProcuringToolsForService = ({id}) => {
    const [assetProcuring,setAssetProcuring]=useState([])
  const AssetReqireForProcuringService=()=>{
      axiosInstance.get("support_page/asset-request-list-of-service-manager/")
          .then((res) => {
              // if (res.data.data[0].ticket_status_for_service_manager === "close") {
                
              setAssetProcuring(res)
              console.log(res?.data?.data?.find((i)=>i.id === Number(id))?.assets)
                  
              // }
          })
          .catch((e) => {
              console.log(e, "rejected")
          })
  }
  useEffect(()=>{
    AssetReqireForProcuringService(assetProcuring,"ttt")
  },[])
  console.log(assetProcuring,"ooo") //ass.dada.dada[]
  const initialValues = {
    date: "",
    ticket_id: "",
    request_quantity: "",
    remarks: "",
    asset_request_id: ""
  };
  const {handleSubmit,handleChange,values} = useFormik({
    initialValues: initialValues,

    onSubmit: () => {
  
      const reqBody = {

        date:"",
        ticket_id: "",      
        request_quantity: "",
        remarks:"",
        asset_request_id:"",
      };
      axiosInstance
        .post("support_page/asset-request-accept-by-service-manager-api-of-bhaaraterp", reqBody)       
        .then((res) => {
          console.log(res)
          console.log(reqBody,"aaaa")
          res.data.response_code===200 && toast.success("Assign Ticket to engineer");
        })
    },
  });
  
  return (
    <div> <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Asset Id</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Asset Name</TableCell>

                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Request Date</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Revert Date</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {assetProcuring?.data?.data[0]?.assets?.map((row)=>{
                return(
                    <>
                   <TableRow>
                       
                          
                        <TableCell component="th" align='center' scope="row">
                            {row.asset_id}
                        </TableCell>
                        <TableCell component="th" align='center' scope="row">
                            {row.asset_name}
                        </TableCell>

                        <TableCell align="center"><p className=''>{row.request_date}</p></TableCell>
                        <TableCell align="center"><p className=''>{row.revert_date}</p></TableCell>
                        <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                        rounded-xl text-white font-bold p-2'>{row.remarks}</p></TableCell>
                        <TableCell align="center"><p className=' rounded-xl text-black p-2'>{row.request_status}
                        </p></TableCell>
                    </TableRow>
                     
                   </>
                )
            })}

        </TableBody>
    </Table>
</TableContainer>
<div className='flex justify-end p-4'>
<button type='submit' variant='contained' >Next</button>
                    {/* <form onSubmit={handleSubmit} >
                   
                    </form> */}
                </div>
</div>
  )
}

export default ProcuringToolsForService