import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";



const Service = () => {
  const [Status, setStatus] = React.useState('');
  const [data, setdata] = useState();

  console.log(data, "acff");
  const handleChange = (event) => {
      setStatus(event.target.value);
    };

  return (
    <>

<div className="bg-white p-10">
    <div className="">
      <TableContainer component={Paper}>
        <Table>
          <TableHead className="table1 ">
            <TableRow>
              <TableCell className="!font-bold bg-white">Services</TableCell>
              <TableCell className="bg-pink-100 !font-bold ">CCTV</TableCell>             
              <TableCell className="!font-bold bg-white">Deep Freezer</TableCell>
              <TableCell className="bg-pink-100 !font-bold ">Dishwasher</TableCell>
              <TableCell className="!font-bold bg-white">Refrigerators</TableCell>
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
    </div>

    <div className="">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="bg-pink-100 !font-bold ">Geyser Repair</TableCell>
              <TableCell className="!font-bold">Ice Maker</TableCell>             
              <TableCell className="bg-pink-100 !font-bold ">Inverter Battery</TableCell>
              <TableCell className="!font-bold">Mixer Grinders</TableCell>
              <TableCell className="bg-pink-100 !font-bold ">Laptop</TableCell>              
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
    </div>

    <div className="">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="!font-bold ">Microwave</TableCell>
              <TableCell className="bg-pink-100 !font-bold ">Oven & Heaters</TableCell>             
              <TableCell className="!font-bold">Room Heater</TableCell>
              <TableCell className=" bg-pink-100 !font-bold ">TV/LED</TableCell>
              <TableCell className="!font-bold ">Vacuum Cleaner</TableCell>              
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
    </div>

    <div className="">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="bg-pink-100 !font-bold ">Washing Machine</TableCell>
              <TableCell className="!font-bold">Water Cooler</TableCell>             
              <TableCell className="bg-pink-100 !font-bold ">RO water Purifier</TableCell>
              <TableCell className="!font-bold">Kitchen Hub</TableCell>
              <TableCell className="bg-pink-100 !font-bold ">Chimney</TableCell>              
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
    </div>

  </div>
    </>

  )
}

export default Service

