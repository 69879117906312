import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ReactApexChart from "react-apexcharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
const Utilization = () => {
  const state = {
    series: [70],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Total Engineers"],
    },
  };
const [utilizations,setUtilizations]=useState()

const URLUtilization="http://192.168.1.90:9095/support_page/service-engineer-utilization-chat-graph/?store_id=1"
  const ServiceManegerUtilization=async()=>{
    try {
      const response = await axios
        .get(URLUtilization
          ,
          {
            headers: {
              Authorization: '6b677f3bc7a242d7abcfb86823a505f3',
              "Content-Type": 'application/json',
            }
          }
        )
        setUtilizations(response)
      console.log(response)
    } catch (error) { }
  };
useEffect(() => {
  ServiceManegerUtilization();
}, []);

  //console.log(utilizations?.data?.service_engineer_list)

  return (
    <>
      <div>
        <div className="grid grid-cols-3 items-end">
          <div className=" col-start-3 grid grid-cols-2 ">
            <div className="col-start-2 flex">
              <input
                type="text"
                className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
                placeholder="Search..."
              />
              <button className=" leneargradient p-2 rounded-r-3xl">
                <SearchIcon className="text-white  " fontSize="large" />
              </button>
            </div>
          </div>
        </div>
        <div className="text-[#86048A] text-2xl font-bold">
          Service Engineer Utilization
        </div>
        <div className="shadow-slate-400 shadow-md grid grid-cols-2 bg-white">
          <div className="">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height={350}
            />
          </div>
          <div className="pt-8">
            <p className="text-2xl text-[#9291A5]">Total Engineers</p>
            <p className="pt-20 space-x-10">
              <span>
                <input type="radio" /> Working &nbsp;{utilizations?.data?.working_engineer}
              </span>{" "}
              <span>
                <input type="radio" /> Non-Working
              </span>
            </p>
          </div>
        </div>
        <div className="text-2xl font-bold text-[#86048A] pt-8">
          Working Engineers Status
        </div>
        <div className="shadow-slate-400 shadow-md pt-2">
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="!p-2"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Ticket
                  </TableCell>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Engineer Name
                  </TableCell>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Resolution Date
                  </TableCell>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="!text-[#5E697B] !font-bold !text-lg"
                    align="center"
                  >
                    Ticket ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {utilizations?.data?.service_engineer_list?.map((row) => (
                  <TableRow
                    key={row.Start}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.issue_type}
                    </TableCell>
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      align="center"
                    >
                      {row.full_name}
                    </TableCell>
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      align="center"
                    >
                      {row.job_start_date}
                    </TableCell>
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      align="center"
                    >
                      {row.job_close_date}
                    </TableCell>
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      align="center"
                    >
                      {row.ticket_status}
                    </TableCell>
                    <TableCell
                      className="!text-[#8392AB] !text-base"
                      align="center"
                    >
                      <button className="border p-2 button1 rounded-2xl text-white font-bold text-base">
                        {row.ticket_no}
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Utilization;
