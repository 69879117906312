import React, { useState } from 'react'
import EngineeringIcon from '@mui/icons-material/Engineering';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Link } from 'react-router-dom';
import Service from "../../Tabs/Service"
import Subscription from "../../Tabs/Subscription"
import Uses from "../../Tabs/Uses"
import AMC from "../../Tabs/AMC"


const Tab1 = () => {


  const [click, setClick] = useState("");
  const [navigate, useNavigate] =useState("");
  function handleclick(data) {

    setClick(data);
  }

  return (

    

   <>
   <div className=''>
   {/* <div className="flex flex-row justify-between p-3 border-2 shadow-2xl ">
                <div className="flex gap-5 space-x-10">
                <button className="rounded text-white font-bold p-3  text-md bg-gradient-to-r from-purple-800
                 to-pink-700 space-x-4">
                    <EngineeringIcon className="text-purple-800 bg-white border rounded"/>
                    <span className="text-white font-bold text-md">
                   Service Engineer
                    </span>
                  </button>

                  <button className="rounded border-2 border-purple-700 text-white font-bold p-3 text-md space-x-4">
                    <SupportAgentIcon className="text-white bg-gradient-to-r from-purple-800
                 to-pink-700 border rounded"/>
                    <span className="text-pink-800 font-bold text-md">
                   Service Manager
                    </span>
                  </button>
                </div>
              </div> */}

  
              <div className='flex flex-row text-center p-5'>
                <p className='text-pink-800 font-bold text-md'>Service Request Categories</p>
              </div>

              <div className="flex flex-row bg-gradient-to-r from-pink-800
                 to-purple-700 justify-between p-1">

                <div className=''>
                <button onClick={()=> handleclick("service")}
                className={`${
                  click === "service" ? "!text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
               Service</button>
                </div>


                <div className=''>
                <button onClick={()=> handleclick("subscription")}
                className={`${
                  click === "subscription" ?" !text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
               Subscription</button>
                </div>


                <div className=''>
                <button onClick={()=> handleclick("amc")}
                className={`${
                  click === "amc" ? "!text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
              AMC</button>
                </div>


                <div className=''>
                <button onClick={()=> handleclick("uses")}
                className={`${
                  click === "uses" ? "!text-purple-700 bg-white border-2 border-b-2 border-b-white" : ""} rounded 
                  text-md font-bold  text-white
                   shadow-2xl w-40 p-3 border-2 `}>
               Uses</button>
                </div>

              </div>


        <div className="mt-5">

          {click === "service" && <Service/>}
          {click === "subscription" && <Subscription/>}
          {click === "amc" && <AMC/>}
          {click === "uses" && <Uses/>}

        </div>

       </div>
 
   </>
  )
}

export default Tab1