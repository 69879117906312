import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TableData1 } from '../../../FirstTimeFixedRate/TableData'
import axiosInstance from '../../../../config/axios'
import { API_URLS } from '../../../../config/apiUrls'

const DelayCloseService = () => {
    const [delaytimecloseticket, setDelaytimecloseticket] = useState([])
    const DelayCloseServiceApi = () => {
        axiosInstance.get(API_URLS.ServiceEfficency)
            .then((res) => {
                setDelaytimecloseticket(res.data.data)
                console.log(res.data.data, "pks1")
            })
            .catch((e) => {
                console.log(e)
            })
    }
    useEffect(() => {
        DelayCloseServiceApi()
    }, [])
    console.log(delaytimecloseticket, "pks")
    return (
        <>
            <div className=' p-4'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Service</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Engineer Name</TableCell>

                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                                <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {delaytimecloseticket.map((row) => {
                                return (
                                    <>
                                        {
                                            (row?.ticket_status_for_service_manager === "Pending" || row?.ticket_status_for_service_manager === "Progress") ?
                                                <TableRow
                                                    key={row.id}
                                                >
                                                    <TableCell component="th" align='center' scope="row">
                                                        {row.service}
                                                    </TableCell>
                                                    <TableCell component="th" align='center' scope="row">
                                                        {row.service_engineer}
                                                    </TableCell>

                                                    <TableCell align="center"><p className=''>{row.job_start_date}</p></TableCell>
                                                    <TableCell align="center"><p className=''>{row.ticket_closing_date}</p></TableCell>
                                                    <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                                 rounded-xl text-white font-bold p-2'>{row.ticket_no}</p></TableCell>
                                                    <TableCell align="center"><p className=' rounded-xl text-black p-2'>{row.ticket_status_for_service_manager}
                                                    </p></TableCell>
                                                </TableRow>:""
                                        
                                        }
                                    </>

                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}

export default DelayCloseService