import React from "react";

const Asset = () => {
  return (
    <>
      <div className="p-5">
        <div className="p-16 shadow-lg shadow-slate-300">
          <div className="text-2xl p-4 font-bold text-[#86048A]">
            Request For Asset
          </div>
          <div className="pt-5">
            <div className="asset text-white font-bold p-4  text-xl rounded-lg">
              {" "}
              Recommended Asset For service
            </div>
          </div>
          <div className="grid grid-cols-4 p-5">
            <div className="flex flex-col gap-2">
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" className="font-medium" />
                &nbsp;&nbsp;&nbsp; Adjustable wrench
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Drill or driver
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Socket or ratcher set
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Screwdriver
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Adjustable wrench
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Voltage tester
              </div>
            </div>
            <div className="flex flex-col  gap-2">
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Multimeter
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp; Drill or driver
              </div>
            </div>
            <div className="flex flex-col gap-2 ">
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp;Nut driver
              </div>
              <div className="text-xl text-[#1E1E1E]">
                {" "}
                <input type="checkbox" />
                &nbsp;&nbsp;&nbsp;Needle-nose pliers
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Asset;
