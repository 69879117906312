import {Search } from '@mui/icons-material';
import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReactApexChart from 'react-apexcharts';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";

const Averagetime = () => {

    const state = {
          
        series: [{
          name: 'Net Profit',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
          name: 'Revenue',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
          name: 'Free Cash Flow',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          },
          yaxis: {
            title: {
              text: '$ (thousands)'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "$ " + val + " thousands"
              }
            }
          }
        },
      
      
      };

    //   const [filter, setfilter] = React.useState('');

    //   const handleChange = (event: SelectChangeEvent) => {
    //     setfilter(event.target.value);
    //   };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

  return (
    <>

<div className="grid grid-cols-3 p-5">
          <div className="col-start-3 grid grid-cols-4 gap-5">
            <div className=" flex col-span-2 ">
              <input
                type="text"
                className="leneargradient outline-none p-1 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
                placeholder="Search..."
              />
              <button className=" leneargradient p-2 rounded-r-3xl">
                <SearchIcon className="text-white  " fontSize="large" />
              </button>
            </div>
            <div className="flex">
              {" "}
              <button
                className="w-10/12 leneargradient rounded-l-3xl p-2 text-xl text-white"
                onClick={handleClick}
              >
                Filter
              </button>
              <button className="leneargradient rounded-r-3xl p-1">
                <FilterAltIcon className="text-white" />
              </button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="!w-96"
              >
                <MenuItem onClick={handleClose} className=" !w-36">
                  Date
                </MenuItem>
                <MenuItem onClick={handleClose}>Status</MenuItem>
              </Menu>
            </div>

            <div className="">
              <button className="bg-blue-600 p-1 rounded-full text-center">
                <AddIcon className="text-white " fontSize="large" />
              </button>
            </div>
          </div>
        </div>


<div sx={{ maxHeight: 440 }}>
<p className='font-bold text-purple-800 p-3 text-xl'>Average Response Time</p>
<div id="chart">
<ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
</div>
</div>
    </>

  )
}

export default Averagetime