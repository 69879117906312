import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { API_URLS } from "../../config/apiUrls";

const Assignment = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function createData(Start, Resolution, Ticket, status, spare, Asset) {
    return { Start, Resolution, Ticket, status, spare, Asset };
  }
  const rows = [
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
    createData(
      "04/08/2023",
      "05/08/2023",
      "#313566",
      "Progress",
      "Asset",
      "Screwdriver"
    ),
  ];

  const [assigntask,setAssignTask]=useState()
  const AssignedTask = async () => {
   
   
   axiosInstance.get(API_URLS.AssignedTask)
   .then((response)=>{
    setAssignTask(response?.data?.data)
   })
   .catch((e)=>{
    console.log(e)
   })
  }
  useEffect(() => {
    AssignedTask();
  }, [])
   console.log(assigntask,"ppp")



  return (
    <>
      <div className="grid grid-cols-3 ">
        <div className="col-start-3 grid grid-cols-4 gap-2 ">
          <div className=" flex  col-start-2 col-span-2">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>
          <div className="flex">
            
            <button
              className="w-10/12 leneargradient rounded-l-3xl p-2 text-xl text-white"
              onClick={handleClick}
            >
              Filter
            </button>
            <button className="leneargradient rounded-r-3xl p-2">
              <FilterAltIcon className="text-white" />
            </button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem className=" !w-36">Daily</MenuItem>
              <MenuItem className=" !w-36 !border-t-2 border">Weekly</MenuItem>
              <MenuItem className=" !w-36">Monthly</MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <div className="pt-2">
        <div className="text-2xl font-bold text-[#86048A] !p-2">
          Assigned Task
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="!p-2"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Start Date
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Resolution Date
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Ticket id
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.Start}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Start}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.Resolution}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    <button
                      onClick={() => {
                        navigate("/ticket_details");
                      }}
                      className="border p-2 button1 rounded-2xl text-white font-bold text-base"
                    >
                      {row.Ticket}
                    </button>
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Assignment;
