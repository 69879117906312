import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tabledata } from "../../../MockT2";

const Subscription = () => {

  const [Status, setStatus] = React.useState('');
  const [data, setdata] = useState();


  console.log(data, "acff");
  const handleChange = (event) => {
      setStatus(event.target.value);
    };

  return (
    <>

<div className="bg-white">
    <div className="">
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead className="table1 ">
            <TableRow>
              <TableCell className="!font-bold border-l border-r border-black border-opacity-30">Services</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30">Start Date</TableCell>
             
              <TableCell className="!font-bold border-r border-black border-opacity-30">Resolution Date</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30">Ticket ID</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30">Subscription</TableCell>
              <TableCell className="!font-bold border-r border-black border-opacity-30">status</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>

            {Tabledata.map((i) => (

              <TableRow 
              key={i.name}
            >
              <TableCell className="border-l border-r border-black border-opacity-30 !text-[#8392AB]">{i.Services}</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB]">{i.StartDate}</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB]">{i.ResolutionDate}</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB]">
                <p className="p-2 text-white bg-gradient-to-r from-purple-800
                 to-pink-700 rounded text-center">{i.TicketID}</p></TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB]">{i.Subscription}</TableCell>
              <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB]">{i.Status}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  </div>
    </>

  )
}

export default Subscription