
import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Menu from '@mui/material/Menu';
import React from 'react'

import { TableData1 } from '../FirstTimeFixedRate/TableData';

const SLACompliance = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            
                <div className='flex gap-2 justify-end p-2'>
                    <div className=" flex  ">
                        <input
                            type="text"
                            className="leneargradient outline-none px-4  text-white placeholder:text-white text-lg rounded-l-3xl w-10/12"
                            placeholder="Search..."
                        />
                        <button className=" leneargradient  rounded-r-3xl">
                            <SearchIcon className="text-white " fontSize="large" />
                        </button>
                    </div>
                    <div className="flex">
                        <button
                            className="w-10/12 leneargradient rounded-l-3xl p-2 text-lg text-white"
                            onClick={handleClick}
                        >
                            Filter
                        </button>
                        <button className="leneargradient rounded-r-3xl p-2">
                            <FilterAltIcon className="text-white" />
                        </button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            className="!w-96"
                        >
                            <MenuItem onClick={handleClose} className=" !w-36">
                                Date
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Status</MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='flex flex-col  shadow-lg '>
                    <div className='flex p-4 '><h1 className='text-xl font-serif text-[#86048A] font-bold px-4'>SLA Compliance</
                    h1></div>
                    <div className='px-4'>
                        <TableContainer component={Paper} >
                            <Table sx={{ minWidth: 650 }} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='table1 !text-gray-600 !font-bold' align='center' >Ticket</TableCell>
                                        <TableCell className='table1 !text-gray-600 !font-bold' align="center">Start Date</TableCell>
                                        <TableCell className='table1 !text-gray-600 !font-bold' align="center">Resolution Date</TableCell>
                                        <TableCell className='table1 !text-gray-600 !font-bold' align="center">Ticket Id</TableCell>
                                        <TableCell className='table1 !text-gray-600 !font-bold' align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {TableData1.map((row) => (
                                        <TableRow
                                            key={row.id}
                                        >
                                            <TableCell align='center' >
                                                {row.Ticket}
                                            </TableCell>
                                            <TableCell align="center"><p className=''>{row.Start_date}</p></TableCell>
                                            <TableCell align="center"><p className=''>{row.resolution_date}</p></TableCell>
                                            <TableCell align="center"> <p className='  bg-gradient-to-r from-[#9C06AA] to-[#CB3C68] 
                                               rounded-xl text-white font-bold p-2'>{row.ticket_id}</p></TableCell>
                                            <TableCell align="center"><p className='bg-[#21B943] rounded-xl text-white p-2'>{row.status}
                                            </p></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            
        </>
    )
}
export default SLACompliance