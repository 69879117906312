import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuItem from "@mui/material/MenuItem";

import {
  Avatar,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  Rating,
  Typography,
} from "@mui/material";
const Customer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const engineer = [
    {
      name: "Amritesh Kumar",
    },
    {
      name: "Amritesh Kumar",
    },
    {
      name: "Amritesh Kumar",
    },
    {
      name: "Amritesh Kumar",
    },
    {
      name: "Amritesh Kumar",
    },
    {
      name: "Amritesh Kumar",
    },
  ];
  const rows = [
    {
      service: "Air Condition",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Deep Freezer",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Mixer Grinder",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Oven & Heaters",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Air Condition",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Air Condition",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Air Condition",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
    {
      service: "Air Condition",
      Customer: "Rajesh verma",
      Start: "04/08/2023",
      Resolution: "05/08/2023",
      ticketid: "#313566",
      status: "open",
      Rating: <Rating name="read-only" value={5} readOnly />,
      Feedback: "VeryFast/impression Service,Highly,Recomm..",
    },
  ];
  return (
    <>
      <div className="grid sm:grid-cols-1   lg:grid-cols-3 ">
        <div className="text-2xl font-bold text-[#86048A] !p-2">
          Engineer Name: Amritesh Kumar
        </div>
        <div className="col-start-3 grid grid-cols-4 gap-2 ">
          <div className=" flex  col-start-2 col-span-2">
            <input
              type="text"
              className="leneargradient outline-none p-2 text-white placeholder:text-white text-xl rounded-l-3xl w-10/12"
              placeholder="Search..."
            />
            <button className=" leneargradient p-2 rounded-r-3xl">
              <SearchIcon className="text-white  " fontSize="large" />
            </button>
          </div>
          <div className="flex">
            {" "}
            <button
              className="w-10/12 leneargradient rounded-l-3xl p-2 text-xl text-white"
              onClick={handleClick}
            >
              Engineer
            </button>
            <button className="leneargradient rounded-r-3xl p-2">
              <FilterAltIcon className="text-white" />
            </button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className=""
            >
              <MenuItem className="!w-80" onClick={handleClose}>
                <ListItemIcon>
                  <ArrowBackIcon className="text-blue-400" />
                </ListItemIcon>
                <ListItemText className="!text-2xl !font-bold">
                  Engineers
                </ListItemText>
              </MenuItem>
              {engineer.map((i) => {
                return (
                  <div>
                    <MenuItem className=" !w-80">
                      <ListItemIcon>
                        <Avatar />
                      </ListItemIcon>
                      <ListItemText>{i.name}</ListItemText>

                      <Typography
                        onClick={handleClick}
                        variant="body2"
                        color="text.secondary"
                      >
                        <Checkbox
                          onClick={handleClose}
                          className="!outline-none"
                        />
                      </Typography>
                    </MenuItem>
                  </div>
                );
              })}
            </Menu>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div className="text-2xl font-bold text-[#86048A] !p-2">
          Customer Satisfaction Index
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="!p-2"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Service
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Customer Name
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Start Date
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Resolution Date
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  TicketID
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Rating
                </TableCell>
                <TableCell
                  className="!text-[#5E697B] !font-bold !text-lg"
                  align="center"
                >
                  Feedback
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.service}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.service}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Customer}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Start}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {row.Resolution}
                  </TableCell>

                  <TableCell
                    className="!text-[#83#FF000092AB] !text-base"
                    align="center"
                  >
                    <button className="border p-2 button1 rounded-2xl text-white font-bold text-base">
                      {row.ticketid}
                    </button>
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.status}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.Rating}
                  </TableCell>
                  <TableCell
                    className="!text-[#8392AB] !text-base"
                    align="center"
                  >
                    {row.Feedback}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Customer;
