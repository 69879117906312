import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { API_URLS } from "../../config/apiUrls";

export default function OpenServiceRequest() {
  const [data, setdata] = useState([]);
  const [Status, setStatus] = React.useState("");

  const OpenServiceRequest = () => {
    axiosInstance
      .get(API_URLS.OpenServiceRequest)
      .then((response) => {
        setdata(response?.data?.data);
        // console.log(response,"hello")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    OpenServiceRequest();
  }, []);

  return (
    <>
      <p className="text-xl font-bold text-violet-500 my-3 pl-5">
        Open Service Request
      </p>
      <div className="">
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead className="table1 ">
              <TableRow>
                <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">
                  Start Date
                </TableCell>

                <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">
                  Resolution Date
                </TableCell>
                <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">
                  Ticket ID
                </TableCell>
                <TableCell className="!font-bold border-r border-black border-opacity-30 !text-center">
                  Ticket Type
                </TableCell>
                <TableCell
                  className="border-r border-black border-opacity-30 !text-blue-600 
              !text-center"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {data?.map((i) => (
                <TableRow key={i.name}>
                  <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">
                    {i.date}
                  </TableCell>
                  <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">
                    {i.ticket_closing_date}
                  </TableCell>
                  <TableCell className="border-r border-black border-opacity-30 !text-[#8392AB] !text-center">
                    <Link to="/ticket_no">
                      <p
                        className="p-2 text-white bg-gradient-to-r from-purple-800
                       to-pink-700 rounded text-center"
                      >
                        {i.ticket_no}
                      </p>
                    </Link>
                  </TableCell>
                  <TableCell className=" !text-center border-r border-black border-opacity-30 !text-[#8392AB]">
                    {i.ticket_service_type}
                  </TableCell>
                  <TableCell className=" !text-center border-r border-black border-opacity-30 !text-[#8392AB]">
                    {i.ticket_status_for_service_engineer}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
